import React from "react";
import { Row, Col } from "reactstrap";
import Layout from "../components/layout";
import Section from "../components/section";

const NotFoundPage = () => (
  <Layout>
    <Section title="404 Page not found" id="not-found-container">
      <Row>
        <Col>
          <p>
            Wups... ! It looks like you took a wrong turn. Maybe you went too
            fast?
          </p>
        </Col>
      </Row>
    </Section>
  </Layout>
);

export default NotFoundPage;
